.navigation {
  background-color: #15243dd2;
  width: 100%;
  padding: 20px 0;
  margin-top: 30px;
  text-align: center;
  li {
    display: inline-block;
    cursor: pointer;
    margin: 0 8px;
    transition: all 0.5s;
    a {
      color: $background;
      font-weight: 500;
      border-radius: 39% 31% 43% 40% / 50% 53% 59% 58%;
      padding: 0 16px;
      background-color: #ccd6f6;
      &:hover {
        background-color: #58a6ffbc;
      }
    }
    &.disabled {
      cursor: default;
      a {
        background-color: #8d8d8d;
        &:hover {
          background-color: #8d8d8d;
        }
      }
    }
    &.selected {
      cursor: default;
      a {
        background-color: #438ce0;
        color: #fff;
        &:hover {
          background-color: #438ce0;
        }
      }
    }
  }
}
