@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400&family=Rubik:wght@300;400;500;600;700;800&display=swap&family=Open+Sans:wght@300;400;500");
@import "./_files/reset";
@import "./_files/vars";
@import "./_files/grid";
@import "./_files/navigation";
.anim {
  position: relative;
  .hiL {
    $color-green: #c8c8a9;
    $color-brown: #774f38;
    $color-beige: #ece5ce;
    $color-yellow: #f9cdad;
    $color-pink: #fe4365;

    position: absolute;
    right: -65px;
    top: -5px;
    margin: 0;
    display: grid;
    place-items: center;

    p {
      transform: translatey(0px);
      animation: float 5s ease-in-out infinite;
      text-align: center;
      //text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 3px;
      font-size: 15px;
      color: #f6f4f4;
      background-color: #2286f8;
      padding: 10px;
      border-radius: 16px;
      position: relative;
      box-shadow: 20px 20px #2286f852;
      // border: 1px solid $color-green;
    }
    p:after {
      transform: translatey(0px);
      animation: float2 5s ease-in-out infinite;
      content: ".";
      font-weight: bold;
      // -webkit-text-stroke: 0.5px $color-green;
      -webkit-text-fill-color: #2286f8;
      // border: 1px solid $color-green;
      text-shadow: 22px 22px #2286f852;
      text-align: left;
      font-size: 55px;
      width: 55px;
      height: 11px;
      line-height: 30px;
      border-radius: 11px;
      background-color: #2286f8;
      position: absolute;
      display: block;
      bottom: -30px;
      left: 0;
      box-shadow: 22px 22px #2286f852;
      z-index: -2;
    }

    @keyframes float {
      0% {
        transform: translatey(0px);
      }
      50% {
        transform: translatey(-20px);
      }
      100% {
        transform: translatey(0px);
      }
    }

    @keyframes float2 {
      0% {
        line-height: 30px;
        transform: translatey(0px);
      }
      55% {
        transform: translatey(-20px);
      }
      60% {
        line-height: 10px;
      }
      100% {
        line-height: 30px;
        transform: translatey(0px);
      }
    }
  }
}

.menu {
  width: 100%;
  position: fixed;
  padding: 18px 0;
  z-index: 1001;
  box-shadow: 0 1px 0.1px 0 rgba(0, 0, 0, 0.4);
  nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    flex: 1;
    .hamlogo {
      display: flex;
      gap: 30px;
      flex: 1;
    }
    .hamburger {
      display: flex;
      flex: 2;
      justify-content: flex-end;
      .ham {
        display: flex;
        align-items: center;
        gap: 60px;
      }
      gap: 60px;
    }

    .logo {
      display: flex;
      align-items: center;
      text-decoration: none;
      gap: 3px;
      font-size: 1.2rem;
      font-weight: 600;
      &::before,
      &::after {
        content: "<";
        color: $vermelho;
        font-family: "Rubik";
      }
      &::after {
        content: "/>";
      }
    }
    p {
      font-size: 1.05rem;
      span {
        cursor: pointer;
        color: #ccd6f6;
        &:hover {
          color: #fff;
        }
      }
      .active {
        font-weight: 600;
        color: #2287f8 !important;
        &:hover {
          color: #2287f8e8;
        }
      }
      .language {
        color: #2287f8;
        font-weight: 500;
      }
    }

    .theme {
      width: 24px;
      cursor: pointer;
      img {
        object-fit: cover;
        width: 100%;
        border-radius: 50%;
        padding: 1px;
        border: 1px solid #ccd6f6;
      }
    }

    .menu-lista {
      display: flex;
      align-items: center;
      ul {
        li {
          color: #ccd6f6;
          list-style: none;
          display: inline-block;
          padding: 0 15px;
          &:nth-child(1) {
            padding-left: 0;
          }
          &:nth-last-child(1) {
            padding-right: 0;
          }
          font-weight: 500;
          font-size: 16px;
          .link {
            cursor: pointer;
          }
          a {
            color: #ccd6f6;
            font-size: 1.1rem;
            text-decoration: none;
            font-weight: 500;
            &:hover {
              color: #fff;
            }
          }
          small {
            color: #2287f8;
            margin-right: 5px;
            font-weight: 400;
            font-size: 1rem;
          }
        }
      }
    }
  }
}

main {
  width: 100%;
  position: relative;
  .home {
    display: flex;
    width: 100%;
    position: relative;
    .pessoal {
      flex: 2;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100vh;
      position: relative;
      margin-top: 60px;
      .hi {
        height: 250px;
        width: 250px;
        border-radius: 50%;
        -webkit-box-shadow: -12px -2px 5px 7px rgba(0, 0, 0, 0.02);
        -moz-box-shadow: -12px -2px 5px 7px rgba(0, 0, 0, 0.02);
        box-shadow: -12px -2px 5px 7px rgba(0, 0, 0, 0.02);
      }
      h1 {
        font-size: 5.8rem;
        font-family: "Rubik";
        letter-spacing: 0.8px;
        text-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        line-height: 0.9;
        position: relative;
        font-weight: 500;
        color: #ccd6f6;
      }
      h2 {
        font-weight: 400;
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 1.3rem;
        margin: 10px 0 30px;

        span {
          display: flex;
          color: #8892b0;
          align-items: center;
          font-weight: 500;
          gap: 10px;
          &::after {
            content: "";
            height: 8px;
            width: 8px;
            background-color: $vermelho;
            display: inline-block;
            border-radius: 50%;
          }

          &:nth-last-child(1) {
            &::after {
              content: none;
            }
          }
        }
      }
      p {
        font-size: 20px;
        letter-spacing: 0.6px;
        line-height: 1.5;
        text-align: center;
        max-width: 650px;
        margin: 30px 0 50px;
      }
      .button {
        a {
          display: flex;
          align-items: center;
          padding: 8px 15px;
          border-radius: 15px;
          border: 0;
          font-weight: 500;
          cursor: pointer;
          font-size: 1rem;
          background-color: #2287f8;
          border-color: #2287f8;
          color: #ccd6f6;
          &:hover {
            background-color: #2287f8e7;
          }
          &::after {
            content: "‹";
            color: #ccd6f6;
            height: 30px;
            width: 30px;
            background-color: rgba(255, 255, 255, 0.159);
            font-size: 20px;
            margin-left: 10px;
            transform: rotate(270deg);
            font-family: "Rubik";
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
    .git {
      flex: 1;
      padding: 200px 80px 100px 0;

      .git-data {
        border-bottom: 1px solid #6873812b;
        border-radius: 1rem;
        height: 800px;
        overflow-y: scroll;
        b {
          color: #fff;
          margin: 0 4px;
          font-weight: 500;
        }
        .git-n {
          display: flex;
          border-bottom: 1px solid #687381;
          background-color: #3c4454;
          padding: 10px;
          gap: 10px;
          align-items: center;
          img {
            height: 40px;
            width: 40px;
            object-fit: cover;
            border-radius: 50%;
          }
          div {
            flex: 1;
            h1 {
              display: flex;
              align-items: center;
              justify-content: space-between;
              span {
                font-size: 18px;
                color: #2287f8;
              }
              small {
                font-size: 14px;
                color: #fff;
                font-weight: 400;
              }
              a {
                svg {
                  fill: #fff;
                  width: 20px;
                  &:hover {
                    fill: #dedede;
                  }
                }
              }
            }
            p {
              display: flex;
              align-items: center;
              font-size: 14px;
              gap: 20px;
              span {
                &:nth-child(1) {
                  color: #999797;
                }
                display: flex;
                align-items: center;
                color: #fff;
                svg {
                  fill: #fff;
                  margin-right: 6px;
                }
                small {
                  height: 5px;
                  width: 5px;
                  background-color: #2287f8;
                  border-radius: 50%;
                  margin: 0 8px;
                }
              }
            }
          }
        }
        .repositors {
          .repo {
            //color: #fff !important;
            border-bottom: 1px solid #6873812b;
            padding: 20px;
            background-color: #edf0f124;
            &:nth-last-child(1) {
              border-bottom: none;
            }

            h3 {
              display: flex;
              align-items: center;
              gap: 10px;

              a {
                color: #2287f8;
                text-decoration: none;
                font-weight: 600;
                font-size: 20px;

                &:hover {
                  text-decoration: underline;
                }
              }
              small {
                color: #ccd6f6;
                padding: 0px 7px;
                border: 1px solid #ccd6f6;
                border-radius: 2em;
                font-size: 12px;
                font-weight: 400;
              }
            }
            p {
              color: #fff;
              font-size: 14px;
              margin: 3px 0;
            }
            .tags {
              margin: 10px 0 15px;
              span {
                display: inline-block;
                padding: 0 7px;
                font-size: 12px;
                font-weight: 500;
                line-height: 18px;
                white-space: nowrap;
                border: 1px solid transparent;
                border-radius: 2em;
                padding-right: 10px;
                padding-left: 10px;
                line-height: 22px;
                color: #2287f8;
                background-color: #388bfd26;
                border: 1px solid #00000000;
                margin-right: 3px;
                margin-top: 5px;
              }
            }
            .footer {
              display: flex;
              align-items: center;
              gap: 20px;
              font-size: 12px;
              span {
                color: #ccd6f6;
                display: flex;
                align-items: center;
                gap: 6px;
                svg {
                  fill: #ccd6f6;
                }
                small {
                  height: 10px;
                  width: 10px;
                  display: inline-block;
                  background-color: #3d6117;
                  border-radius: 50%;
                  border: 1px solid #ffffff72;
                }
              }
            }
          }
        }
      }
    }
    .social-media {
      //position: fixed;
      /* left: 40px; */
      /* bottom: 100px; */
      margin-top: 10px;
      //z-index: 1;
      /* &::after {
        content: "";
        height: 120px;
        width: 1px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 30px;
        background-color: #fff;
      } */
      ul {
        li {
          display: inline-block;
          padding: 10px;
          a {
            position: relative;
            @keyframes dash {
              from {
                stroke-dashoffset: 200;
              }
              to {
                stroke-dashoffset: 0;
              }
            }
            svg {
              width: 26px;
              stroke: #ffffffd7;
              &:hover {
                path,
                rect,
                circle,
                line {
                  stroke-dasharray: 200;
                  stroke-dashoffset: 200;
                  animation: dash 2s linear infinite;
                  stroke: #2287f8;
                }
              }
            }
          }
        }
      }
    }
  }
  .separador {
    display: flex;
    position: relative;
    align-items: center;
    padding: 85px 0 50px;

    h1 {
      font-size: 22px;
      color: #fff;
      white-space: nowrap;
      font-weight: 500;
      small {
        color: #2287f8;
        margin-right: 5px;
        font-weight: 300;
        font-size: 18px;
        font-weight: 500;
      }
    }

    &::after {
      content: "";
      height: 0.5px;
      width: 100%;
      background-color: #2286f856;
      margin-left: 30px;
    }
    /* &.sb, &.sk {
            &::before{
                content: "Sobre Mim";
                text-transform: uppercase;
                font-size: 70px;
                color: #2287f8;
                font-family: "Rubik";
                position: absolute;
                top: -40px;
                opacity: .4;
                left:50%;  
                transform: translateX(-50%);
                z-index: -1; 
            }
        }
        &.sk{
            &::before{
                content: "Habilidades";
            }
        } */
  }
  .sobre {
    display: grid;
    align-items: center;
    justify-content: space-between;
    .stack {
      width: 85%;
      max-width: 400px;
      transition: .25s ease;
      display: block;
      margin: 0 auto;
      transform: rotate(0deg);
    }
    
    .card {
      border: 3px solid #2286f854;
      background-color: #030a15;
      position: relative;
      padding: 5%;
      &:before, &:after {
        content: "";
        display: block;
        position: absolute;
        height: 100%;
        width: 100%;
        border: 3px solid #2286f854;
        background-color: #030a15;
        transform-origin: center center;
        z-index: -1;
        top: 0;
        left: 0;
      }
      
      &:before {
        transform: translatey(-2%) rotate(-6deg);
      }
      
      &:after {
        transform: translatey(2%) rotate(6deg);
      }
    }
    .image {
      width: 100%;
      border: 3px solid #2286f854;
      background-color: #EEE;
      position: relative;
    }
    
    .browser-warning {
      margin-bottom: 4rem;
    }
    
    @supports (aspect-ratio: 1 / 1) {
      .browser-warning {
        display: none;
      }
    }
    .texto {
      p {
        max-width: 800px;
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 1.7;
        margin-bottom: 15px;
        font-family: "Open Sans";
        small{
          color: #2287f8;
          font-weight: 500;
          font-size: 18px;
        }
      }
      a {
        display: flex;
        align-items: center;
        padding: 8px 15px;
        border: 1.5px solid #2287f8;
        background-color: #2287f8;
        border-radius: 15px;
        width: 182px;
        text-decoration: none;
        position: relative;
        box-shadow: 0 0.2px 0.3px 0 rgba(0, 0, 0, 0.1);
        color: #fff;
        &::after {
          content: "‹";
          color: #2287f8;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 25px;
          width: 25px;
          background-color: #ffffff68;
          font-size: 20px;
          transform: rotate(270deg);
          font-family: "Rubik";
          border-radius: 10px;
          margin-left: 10px;
        }
        &:hover {
          border-color: #58a6ffe4;
        }
      }
      ul {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        margin: 20px 0 30px;
        li {
          text-transform: uppercase;
          margin-bottom: 8px;
          color: #8892b0;
          &::before {
            content: "▹";
            left: 0px;
            color: #2287f8;
            font-size: 16px;
            margin-right: 8px;
            //line-height: 12px;
            display: inline-block;
          }
        }
      }
    }
  }
  .projectos {
    display: grid;
    align-items: center;
    justify-content: space-between;
    margin-top: 60px;
    .image {
      width: 100%;
      height: 350px;
      border-radius: 1rem;
      box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 1rem;
      }
    }
    .texto {
      position: relative;
      .descricao {
        padding: 15px;
        position: absolute;
        width: 580px;
        right: -60px;
        top: 50%;
        transform: translateY(-50%);
        background-color: #15243df7;
        border-radius: 1rem;
        box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
          0 4px 6px -2px rgba(0, 0, 0, 0.05);

        border: 1px solid rgba(255, 255, 255, 0.16);
        border-top: 0px;
        h1 {
          font-size: 24px;
          font-weight: 500;
          font-weight: bold;
          color: #ccd6f6;
        }
        .tools {
          margin: 5px 0 8px;
          span {
            display: inline-flex;
            vertical-align: top;
            -webkit-box-align: center;
            align-items: center;
            max-width: 100%;
            font-weight: 500;
            line-height: 1.2;
            outline: transparent solid 2px;
            outline-offset: 2px;
            min-height: 1.45rem;
            min-width: 1.25rem;
            font-size: 0.75rem;
            padding: 0 5px;
            /* padding-inline-start: 0.5rem;
            padding-inline-end: 0.5rem; */
            border-radius: 0.375rem;
            margin-left: 6px;
            &:nth-child(1) {
              margin-left: 0px;
            }
            span {
              //line-height: 1.2;
              overflow: hidden;
              margin-left: 0px;
              //text-overflow: ellipsis;
              white-space: nowrap;
              font-weight: 600;
            }
            i {
              //color: #e5e5e5;
              font-size: 12px;
            }
          }
        }
        p {
          font-family: "Open Sans";
          font-size: 16px;
          color: #f8f8fa !important;
          a {
            font-size: 15px;
            text-decoration-color: #2287f8;
            font-size: 15px;
            line-height: 0;
          }
        }
        ul {
          margin-top: 15px;
          display: flex;
          align-items: center;
          gap: 10px;
          li {
            a {
              svg {
                width: 20px;
                stroke: #ccd6f6;
                &:hover {
                  stroke: #2287f8;
                }
              }
            }
          }
        }
      }
    }

    &.grid-f-2 {
      .texto {
        .descricao {
          left: -60px;
        }
      }
    }
    &:first-of-type {
      margin-top: 0px !important;
    }
  }
  .contatos {
    background-color: #15243dd2;
    width: 100%;
    padding: 50px 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    gap: 40px;
    margin-bottom: 40px;
    .img {
      height: 140px;
      width: 140px;
      border-radius: 50%;
      border: 6px solid #2287f8;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    h1 {
      max-width: 74%;
      font-size: 17px;
      color: #ccd6f6;
      font-weight: 500;
      text-align: center;
      small {
        text-decoration: underline;
        text-decoration-color: #2287f8;
      }
    }
    .social {
      display: flex;
      align-items: center;
      gap: 30px;
      a {
        svg {
          width: 26px;
          stroke: #ccd6f6;
          &:hover {
            stroke: #2287f8;
          }
        }
      }
    }
    div {
      text-align: center;
      p {
        font-family: "Open Sans";
        color: #ccd6f6;
        font-size: 16px;
        small {
          font-weight: 600;
          color: #2287f8 !important;
        }
      }
    }
  }
  footer {
    text-align: center;
    padding: 20px 0;
    small {
      a {
        font-weight: 600;
        text-decoration-color: #2287f8;
      }
      color: #ccd6f6;
    }
  }
}

.center, .center-light {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #030a15;
  .ring {
    position: absolute;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    animation: ring 2s linear infinite;
    &::before {
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      border-radius: 50%;
      box-shadow: 0 0 5px rgba(255, 255, 255, 0.3);
    }
  }
  @keyframes ring {
    0% {
      transform: rotate(0deg);
      box-shadow: 1px 5px 2px #ee344c;
    }
    50% {
      transform: rotate(180deg);
      box-shadow: 1px 5px 2px #ffffff63;
    }
    100% {
      transform: rotate(360deg);
      box-shadow: 1px 5px 2px #2287f8;
    }
  }
  span {
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 200px;
    animation: text 3s ease-in-out infinite;
  }
  @keyframes text {
    50% {
      color: #fff;
    }
  }
}

.center-light{
  background-color: #f8f8f8;
  span {
    color: #15243d
  }
  @keyframes text {
    50% {
      color: #15243d;
    }
  }
  @keyframes ring {
    0% {
      transform: rotate(0deg);
      box-shadow: 1px 5px 2px #ee344daa;
    }
    50% {
      transform: rotate(180deg);
      box-shadow: 1px 5px 2px #15243d84;
    }
    100% {
      transform: rotate(360deg);
      box-shadow: 1px 5px 2px #2286f891;
    }
  }
}

.top {
  position: fixed;
  z-index: 100;
  animation: fadeIn 0.3s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 5%;
  right: 2%;
  height: 40px;
  width: 40px;
  border-radius: 14px;
  background-color: #2287f8;
  svg {
    fill: #fff;
    width: 26px;
  }
  &:hover {
    background-color: #2287f8b8;
  }
}

.moon {
  background-color: #030a15;
  .menu {
    background-color: #030a15;
    nav {
      .logo {
        color: #ccd6f6;
      }

      .menu-lista {
        display: flex;
        align-items: center;
        ul {
          li {
            color: #ccd6f6;
            a {
              color: #ccd6f6;
              &:hover {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}

.light {
  background-color: #f8f8f8;
  .menu {
    box-shadow: 0 1px 0.1px 0 #0000000f;
    -webkit-backdrop-filter: saturate(90%) blur(7px);
    backdrop-filter: saturate(90%) blur(7px);
    background-color: #fff !important;
    nav {
      .logo {
        color: #030a15d6;
      }
      p {
        span {
          color: #030a15d6;
          &:hover {
            color: #030a15b0;
          }
        }
      }
      .theme {
        img {
          border: 1px solid #030a15d6;
        }
      }
      .hamb {
        display: block;
        span {
          background-color: #030a15d6;
        }
        &.men {
          .bar1 {
            transform: translateY(4px) rotateZ(-45deg);
          }
          .bar2 {
            transform: translateY(-4px) rotateZ(45deg);
          }
        }
      }
      .menu-lista {
        ul {
          li {
            color: #030a15d6;
            a {
              color: #030a15d6;
              &:hover {
                color: #030a15b0;
              }
            }
            small {
              color: #2287f8;
            }
          }
        }
      }
    }
  }
  main {
    .home {
      .pessoal {
        h1 {
          text-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
          color: #15243d;
        }
        .button {
          a {
            color: #fff;
          }
        }
      }
      .git {
        .git-data {
          border-bottom: 1px solid #6873812b;
          b {
            color: #fff;
          }
          .git-n {
            display: flex;
            border-bottom: 1px solid #687381;
            background-color: #3c4454;
            padding: 10px;
            gap: 10px;
            align-items: center;
            img {
              height: 40px;
              width: 40px;
              object-fit: cover;
              border-radius: 50%;
            }
            div {
              flex: 1;
              h1 {
                display: flex;
                align-items: center;
                justify-content: space-between;
                span {
                  font-size: 18px;
                  color: #2287f8;
                }
                small {
                  font-size: 14px;
                  color: #fff;
                  font-weight: 400;
                }
                a {
                  svg {
                    fill: #fff;
                    width: 20px;
                    &:hover {
                      fill: #dedede;
                    }
                  }
                }
              }
              p {
                display: flex;
                align-items: center;
                font-size: 14px;
                gap: 20px;
                span {
                  &:nth-child(1) {
                    color: #999797;
                  }
                  display: flex;
                  align-items: center;
                  color: #fff;
                  svg {
                    fill: #fff;
                    margin-right: 6px;
                  }
                  small {
                    height: 5px;
                    width: 5px;
                    background-color: #2287f8;
                    border-radius: 50%;
                    margin: 0 8px;
                  }
                }
              }
            }
          }
          .repositors {
            .repo {
              border-bottom: 1px solid #6873812b;
              background-color: #fff; //#999d9f33;
              h3 {
                a {
                  color: #2287f8;
                }
                small {
                  color: #3c4454;
                  border: 1px solid #3c4454;
                }
              }
              p {
                color: #3b3b3b;
              }
              .tags {
                span {
                  color: #2286f8;
                  background-color: #2286f826;
                }
              }
              .footer {
                span {
                  color: #687381;
                  svg {
                    fill: #687381;
                  }
                }
              }
            }
          }
        }
      }
      .social-media {
        ul {
          li {
            a {
              svg {
                stroke: #3c4454;
              }
            }
          }
        }
      }
    }
    .separador {
      h1 {
        color: #030a15d6;
      }
    }
    .sobre {
      .img {
        height: 350px;
        width: 350px;
        border: 4px solid #2287f8;
        border-radius: 50%;
        margin-left: 32px;
        //z-index: 1;
        position: relative;
        padding: 6px;
        /* &::before {
          content: "";
          position: absolute;
          bottom: 30px;
          right: 30px;
          border: 5px solid #2287f8;
          height: 350px;
          width: 350px;
          z-index: -1;
          border-radius: 50%;
        } */
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
          border: 4px solid #2287f8;
        }
      }
      .texto {
        p {
          color: #030a15d6;
        }
        ul {
          li {
            color: #030a15d6;
          }
        }
      }
    }
  }
  .sobre {
    .image,.card {
      background-color: #fff;
      border: 3px solid #e6e6e6;
      &:before, &:after {
        background-color: #fff;
        border: 3px solid #e6e6e6;
      }
      
    }
  }
  .projectos {
    .texto {
      .descricao {
        background-color: #ffffff;
        box-shadow: 0 2px 3px -3px rgba(0, 0, 0, 0.1),
          0 4px 6px -2px rgba(0, 0, 0, 0.05);
        border: 1px solid rgba(93, 93, 93, 0.157);
        h1 {
          color: #15243d;
        }
        p {
          color: #030a15d6 !important;
          a {
            color: #030a1577 !important;
            text-decoration-color: #2287f8;
          }
        }
        ul {
          li {
            a {
              svg {
                stroke: #030a15;
                &:hover {
                  stroke: #575757;
                }
              }
            }
          }
        }
      }
    }
    &.grid-f-2 {
      .texto {
        .descricao {
          left: -60px;
        }
      }
    }
    &:first-of-type {
      margin-top: 0px !important;
    }
  }
  .navigation {
    background-color: #fff;
    li {
      &.disabled {
        a {
          background-color: #00000033;
          &:hover {
            background-color: #00000033;
          }
        }
      }
    }
  }
  .contatos {
    background-color: #fff;
    h1 {
      color: #030a15d6;
      small {
        text-decoration-color: #2287f8;
      }
    }
    .social {
      a {
        svg {
          stroke: #030a15d6;
          &:hover {
            stroke: #2287f8;
          }
        }
      }
    }
    div {
      p {
        color: #030a15d6;
      }
    }
  }
  footer {
    background-color: #fff;
    small {
      a {
        color: #030a15d6;
      }
      color: #030a15d6;
    }
  }
}
@media only screen and (max-width: 767px){
  .container {
    width: 100%;
    padding: 0 15px;
  }
  .menu {
    position: fixed;
    box-shadow: unset !important;
    nav {
      display: block;
      .hamlogo {
        display: flex;
        justify-content: space-between;
      }
      .hamburger {
        flex-direction: column;
        z-index: 9999;
        .ham {
          display: flex;
          width: 100%;
          justify-content: space-around;
          margin-top: 20px;
        }
        .menu-lista {
          display: flex;
          align-items: center;
          flex-direction: column;
          width: 100%;
          height: 100vh;
          ul {
            display: flex;
            //align-items: center;
            flex-direction: column;
            gap: 40px;
            margin-top: 10px;
            height: 300px;
            li {
              display: block;
              padding: 0 15px;
              a {
                font-size: 1.1rem;
              }
            }
          }
        }
      }
      .none {
        display: none;
      }
      .hamb {
        display: block;
        span {
          height: 2.5px;
          width: 22px;
          background-color: #ccd6f6;
          display: block;
          margin: 5px 0;
          border-radius: 30px;
          transform: translateY(0) rotateZ(0);
          transition: all 0.5s ease;
        }
        &.men {
          .bar1 {
            transform: translateY(4px) rotateZ(-45deg);
          }
          .bar2 {
            transform: translateY(-4px) rotateZ(45deg);
          }
        }
      }
      .logo {
        font-size: 0.96rem;
      }
      p {
        margin-top: 0px;
        font-size: 14px;
      }
    }
  }
  .anim {
    position: relative;
    .hiL {
      position: absolute;
      right: -45px;
      top: -20px;
      margin: 0;
      display: grid;
      place-items: center;

      p {
        transform: translatey(0px);
        animation: float 5s ease-in-out infinite;
        text-align: center;
        //text-transform: uppercase;
        font-weight: 400;
        letter-spacing: 3px;
        font-size: 0.8rem !important;
        color: #f6f4f4;
        background-color: #2286f8;
        padding: 5px 10px;
        border-radius: 18px;
        position: relative;
        box-shadow: unset;
        box-shadow: 10px 10px #2286f852;
        // border: 1px solid $color-green;
      }
      p:after {
        transform: translatey(0px);
        animation: float2 5s ease-in-out infinite;
        content: ".";
        font-weight: 500;
        // -webkit-text-stroke: 0.5px $color-green;
        -webkit-text-fill-color: #2286f8;
        // border: 1px solid $color-green;
        text-shadow: unset;
        text-align: left;
        font-size: 55px;
        width: 55px;
        height: 8px;
        line-height: 10px;
        border-radius: 11px;
        background-color: #2286f8;
        position: absolute;
        display: block;
        bottom: -30px;
        left: 0;
        box-shadow: unset;
        //z-index: -2;
        text-shadow: 10px 10px #2286f852;
        box-shadow: 10px 10px #2286f852;
      }

      @keyframes float {
        0% {
          transform: translatey(0px);
        }
        50% {
          transform: translatey(-20px);
        }
        100% {
          transform: translatey(0px);
        }
      }

      @keyframes float2 {
        0% {
          line-height: 30px;
          transform: translatey(0px);
        }
        55% {
          transform: translatey(-20px);
        }
        60% {
          line-height: 10px;
        }
        100% {
          line-height: 30px;
          transform: translatey(0px);
        }
      }
    }
  }
  main {
    .home {
      height: 100vh;
      display: flex;
      width: 100%;
      position: relative;
      .pessoal {
        height: calc(100vh - 70px);
        h1 {
          font-size: 2.7rem;
          text-shadow: 0 0 3px rgba(0, 0, 0, 0.114) !important;
        }
        h2 {
          font-size: 1rem;
          span {
            &::after {
              height: 6px;
              width: 6px;
            }
          }
        }
        div {
          //z-index: 2;
          a {
            &::after {
              height: 25px;
              width: 25px;
              font-size: 18px;
            }
          }
        }
        .hi {
          height: 160px;
          width: 160px;
        }

        .frames {
          display: none;
          position: absolute;
          bottom: 50px;
          transform: translateX(-50%);
          left: 50%;
          small {
            color: #8892b0;
            align-items: center;
            font-weight: 500;
            font-size: 12px;
            &::after {
              content: "";
              height: 5px;
              width: 5px;
              background-color: $vermelho;
              display: inline-block;
              border-radius: 50%;
              margin: 0 10px;
            }
            &:nth-last-child(1) {
              &::after {
                content: none;
              }
            }
          }
        }
        .button {
          a {
            padding: 7px 10px;
            border-radius: 15px;
            font-size: 0.8rem;
            &::after {
              height: 25px;
              width: 25px;
              border-radius: 8px;
            }
          }
        }
      }
      .git {
        display: none;
      }
      .social-media {
        ul {
          li {
            a {
              svg {
                width: 20px;
              }
            }
          }
        }
      }
    }
    .separador {
      display: flex;
      position: relative;
      align-items: center;
      padding: 66px 0 30px;

      h1 {
        font-size: 18px;
        color: #fff;
        z-index: 1;
        font-weight: 500;
        small {
          color: #2287f8;
          margin-right: 5px;
          font-weight: 300;
          font-size: 14px;
          font-weight: 500;
        }
      }

      &::after {
        content: "";
        height: 0.5px;
        background-color: #2287f8;
        margin-left: 20px;
      }
      &.sb,
      &.sk {
        &::before {
          content: "Sobre Mim";
          text-transform: uppercase;
          font-size: 70px;
          color: #2287f8;
          font-family: "Rubik";
          position: absolute;
          top: -40px;
          opacity: 0.4;
          left: 50%;
          transform: translateX(-50%);
          z-index: -1;
        }
      }
      &.sk {
        &::before {
          content: "Habilidades";
        }
      }
    }
    .sobre {
      display: block;
      margin-top: 55px;
      .texto {
        margin-top: 30px;
        p {
          max-width: 800px;
          line-height: 1.5;
          font-size:1rem;
          margin-bottom: 15px;
          font-family: "Open Sans";
        }
        a {
          text-decoration: none;
          position: relative;
          background-color: #2287f8;
          font-size: 15px;
          width: 170px;
          color: #fff;
          &::after {
            content: "‹";
            color: #fff;
            text-align: center;
            height: 25px;
            width: 25px;
            font-size: 17px;
            background-color: #ffffff29;
            transform: rotate(270deg);
            font-family: "Rubik";
            border-radius: 10px;
            margin-left: 10px;
          }
          &:hover {
            border-color: #58a6ffe4;
          }
        }
        ul {
          grid-template-columns: 1fr 1fr;
          margin-bottom: 0;
          li {
            &::before {
              font-size: 14px;
            }
          }
        }
      }
    }
    .projectos {
      display: block;
      position: relative;
      margin-bottom: 240px;
      margin-top: 0px;
      box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
        0 4px 6px -2px rgba(0, 0, 0, 0.05);
      .image {
        width: 100%;
        height: 100%;
        img {
          object-fit: contain;
          width: 100%;
          height: 100%;
          border-bottom-left-radius: 0px !important;
          border-bottom-right-radius: 0px !important;
        }
      }
      .texto {
        position: absolute;
        bottom: 0px;
        width: 100%;
        .descricao {
          padding: 10px;
          width: 100%;
          //margin:0 auto;
          left: 0 !important;
          top: 0;
          transform: none;
          background-color: #15243df7;
          border-radius: 1rem;
          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
          h1 {
            font-size: 22px !important;
          }
          p {
            font-family: "Open Sans";
            font-size: 14px;
            color: #8892b0;
            a {
              font-size: 13px;
              text-decoration-color: #2287f8;
            }
          }
        }
      }

      &.grid-f-2 {
        .texto {
          .descricao {
            left: 0;
          }
        }
      }
    }

    .contatos {
      padding: 30px 15px;
      gap: 30px;
      margin-bottom: 30px;
      .img {
        height: 100px;
        width: 100px;
        border: 4px solid #2287f8;
      }
      h1 {
        max-width: 85%;
        font-size: 15px;
        color: #ccd6f6;
        font-weight: 400;
        text-align: center;
      }
      .social {
        display: flex;
        align-items: center;
        gap: 15px;
        a {
          svg {
            width: 20px;
            stroke: #ccd6f6;
            &:hover {
              stroke: #2287f8;
            }
          }
        }
      }
      div {
        text-align: center;
        p {
          font-family: "Open Sans";
          color: #ccd6f6;
          font-size: 13px;
          small {
            font-weight: 600;
            color: #2287f8 !important;
          }
        }
      }
    }
    footer {
      padding: 20px 0 30px;
    }
  }
  .center {
    .ring {
      width: 110px;
      height: 120px;
    }
    span {
      font-size: 12px;
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 1053px){
  .container {
    width: 100%;
    padding: 0 20px;
    
  }
  .menu {
    position: fixed;
    box-shadow: unset !important;
    nav {
      display: block;
      .hamlogo {
        display: flex;
        justify-content: space-between;
      }
      .hamburger {
        flex-direction: column;
        z-index: 9999;
        .ham {
          display: flex;
          width: 100%;
          justify-content: space-around;
          margin-top: 20px;
        }
        .menu-lista {
          display: flex;
          align-items: center;
          flex-direction: column;
          width: 100%;
          height: 100vh;
          ul {
            display: flex;
            flex-direction: column;
            gap: 40px;
            margin-top: 10px;
            height: 300px;
            li {
              display: block;
              padding: 0 15px;
              a {
                font-size: 1.2rem;
              }
            }
          }
        }
      }
      .none {
        display: none;
      }
      .hamb {
        display: block;
        span {
          height: 2.5px;
          width: 22px;
          background-color: #ccd6f6;
          display: block;
          margin: 5px 0;
          border-radius: 30px;
          transform: translateY(0) rotateZ(0);
          transition: all 0.5s ease;
        }
        &.men {
          .bar1 {
            transform: translateY(4px) rotateZ(-45deg);
          }
          .bar2 {
            transform: translateY(-4px) rotateZ(45deg);
          }
        }
      }
      .logo {
        font-size: 1.07rem;
      }
      p {
        margin-top: 0px;
        font-size: 16px;
      }
    }
  }
  .anim {
    position: relative;
    .hiL {
      position: absolute;
      right: -45px;
      top: -20px;
      margin: 0;
      display: grid;
      place-items: center;

      p {
        transform: translatey(0px);
        animation: float 5s ease-in-out infinite;
        text-align: center;
        //text-transform: uppercase;
        font-weight: 400;
        letter-spacing: 3px;
        font-size: 1.08rem !important;
        color: #f6f4f4;
        background-color: #2286f8;
        padding: 5px 10px;
        border-radius: 18px;
        position: relative;
        box-shadow: unset;
        box-shadow: 10px 10px #2286f852;
        // border: 1px solid $color-green;
      }
      p:after {
        transform: translatey(0px);
        animation: float2 5s ease-in-out infinite;
        content: ".";
        font-weight: 500;
        // -webkit-text-stroke: 0.5px $color-green;
        -webkit-text-fill-color: #2286f8;
        // border: 1px solid $color-green;
        text-shadow: unset;
        text-align: left;
        font-size: 55px;
        width: 55px;
        height: 8px;
        line-height: 10px;
        border-radius: 11px;
        background-color: #2286f8;
        position: absolute;
        display: block;
        bottom: -30px;
        left: 0;
        box-shadow: unset;
        //z-index: -2;
        text-shadow: 10px 10px #2286f852;
        box-shadow: 10px 10px #2286f852;
      }

      @keyframes float {
        0% {
          transform: translatey(0px);
        }
        50% {
          transform: translatey(-20px);
        }
        100% {
          transform: translatey(0px);
        }
      }

      @keyframes float2 {
        0% {
          line-height: 30px;
          transform: translatey(0px);
        }
        55% {
          transform: translatey(-20px);
        }
        60% {
          line-height: 10px;
        }
        100% {
          line-height: 30px;
          transform: translatey(0px);
        }
      }
    }
  }
  main {
    .home {
      .pessoal {
        height: calc(100vh - 70px);
        h1 {
          font-size: 3.7rem;
          text-shadow: 0 0 3px rgba(0, 0, 0, 0.114) !important;
        }
        h2 {
          font-size: 1.1rem;
          span {
            &::after {
              height: 6px;
              width: 6px;
            }
          }
        }
        div {
          //z-index: 2;
          a {
            &::after {
              height: 25px;
              width: 25px;
              font-size: 18px;
            }
          }
        }
        .hi {
          height: 180px;
          width: 180px;
        }

        .frames {
          display: none;
        }
        .button {
          a {
            font-size: .94rem;
            &::after {
              height: 25px;
              width: 25px;
              border-radius: 8px;
            }
          }
        }
      }
      .git {
        display: none;
      }
      .social-media {
        ul {
          li {
            a {
              svg {
                width: 20px;
              }
            }
          }
        }
      }
    }
    .separador {
      h1 {
        font-size: 1.3rem;
        small {
          font-size:1rem;
        }
      }

      &::after {
        content: "";
        height: 0.5px;
        background-color: #2286f835;
        margin-left: 20px;
      }
      &.sb,
      &.sk {
        &::before {
          content: "Sobre Mim";
          text-transform: uppercase;
          font-size: 70px;
          color: #2287f8;
          font-family: "Rubik";
          position: absolute;
          top: -40px;
          opacity: 0.4;
          left: 50%;
          transform: translateX(-50%);
          z-index: -1;
        }
      }
      &.sk {
        &::before {
          content: "Habilidades";
        }
      }
    }
    .sobre {
      display: block;
      margin-top: 55px;
      .texto {
        margin-top: 30px;
        p {
          max-width: 100%;
          line-height: 1.5;
          font-size:1.06rem;
          margin-bottom: 15px;
          font-family: "Open Sans";
        }
        a {
          text-decoration: none;
          position: relative;
          background-color: #2287f8;
          font-size: 16px;
          width: 170px;
          color: #fff;
          &::after {
            content: "‹";
            color: #fff;
            text-align: center;
            height: 25px;
            width: 25px;
            font-size: 17px;
            background-color: #ffffff29;
            transform: rotate(270deg);
            font-family: "Rubik";
            border-radius: 10px;
            margin-left: 10px;
          }
          &:hover {
            border-color: #58a6ffe4;
          }
        }
        ul {
          grid-template-columns: 1fr 1fr;
          margin-bottom: 0;
          li {
            &::before {
              font-size: 14px;
            }
          }
        }
      }
      .card {
        border: 3px solid #2286f854 ;
        background-color: #030a15;
        position: relative;
        height: 100%;
        width: 100%;
        padding: 0;;
        &:before, &:after {
          content: "";
          display: block;
          position: absolute;
          
          border: 3px solid #2286f854;
          background-color: #030a15;
          transform-origin: center center;
          z-index: -1;
          top: 0;
          left: 0;
        }
        
        &:before {
          transform: translatey(-2%) rotate(-6deg);
        }
        
        &:after {
          transform: translatey(2%) rotate(6deg);
        }
      }
      .image {
        width: 100%;
        border:unset;
        background-color: #EEE;
        position: relative;
        height: 100%;
        width: 100%;
        img{
          padding: 5%;
        }
      }
    }
    .projectos {
      display: block;
      position: relative;
      margin-bottom: 240px;
      margin-top: 0px;
      box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
        0 4px 6px -2px rgba(0, 0, 0, 0.05);
      .image {
        width: 100%;
        height: 100%;
        img {
          object-fit: contain;
          width: 100%;
          height: 100%;
          border-bottom-left-radius: 0px !important;
          border-bottom-right-radius: 0px !important;
        }
      }
      .texto {
        position: absolute;
        bottom: 0px;
        width: 100%;
        .descricao {
          padding: 10px;
          width: 100%;
          //margin:0 auto;
          left: 0 !important;
          top: 0;
          transform: none;
          background-color: #15243df7;
          border-radius: 1rem;
          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
          h1 {
            font-size: 24px !important;
          }
          p {
            font-size: 1rem;
            a {
              font-size: 14px;
            }
          }
        }
      }

      &.grid-f-2 {
        .texto {
          .descricao {
            left: 0;
          }
        }
      }
    }

    .contatos {
      padding: 30px 15px;
      gap: 30px;
      margin-bottom: 30px;
      .img {
        height: 100px;
        width: 100px;
        border: 4px solid #2287f8;
      }
      h1 {
        max-width: 85%;
        font-size: 16px;
        color: #ccd6f6;
        font-weight: 400;
        text-align: center;
      }
      .social {
        display: flex;
        align-items: center;
        gap: 15px;
        a {
          svg {
            width: 22px;
            stroke: #ccd6f6;
            &:hover {
              stroke: #2287f8;
            }
          }
        }
      }
      div {
        text-align: center;
        p {
          font-family: "Open Sans";
          color: #ccd6f6;
          font-size: 16px;
          small {
            font-weight: 600;
            color: #2287f8 !important;
          }
        }
      }
    }
    footer {
      padding: 20px 0 30px;
    }
  }
  .center {
    .ring {
      width: 110px;
      height: 120px;
    }
    span {
      font-size: 12px;
    }
  }
}
@media only screen and (min-width: 1054px) and (max-width: 1245px) {
  .container {
    width: 100%;
    padding: 0 25px;
  }
  .menu {
    position: fixed;
    box-shadow: unset !important;
    nav {
      display: block;
      .hamlogo {
        display: flex;
        justify-content: space-between;
      }
      .hamburger {
        flex-direction: column;
        z-index: 9999;
        .ham {
          display: flex;
          width: 100%;
          justify-content: space-around;
          margin-top: 20px;
        }
        .menu-lista {
          display: flex;
          align-items: center;
          flex-direction: column;
          width: 100%;
          height: 100vh;
          ul {
            display: flex;
            flex-direction: column;
            gap: 40px;
            margin-top: 10px;
            height: 300px;
            li {
              display: block;
              padding: 0 15px;
              a {
                font-size: 1.2rem;
              }
            }
          }
        }
      }
      .none {
        display: none;
      }
      .hamb {
        display: block;
        span {
          height: 2.5px;
          width: 22px;
          background-color: #ccd6f6;
          display: block;
          margin: 5px 0;
          border-radius: 30px;
          transform: translateY(0) rotateZ(0);
          transition: all 0.5s ease;
        }
        &.men {
          .bar1 {
            transform: translateY(4px) rotateZ(-45deg);
          }
          .bar2 {
            transform: translateY(-4px) rotateZ(45deg);
          }
        }
      }
      .logo {
        font-size: 1.07rem;
      }
      p {
        margin-top: 0px;
        font-size: 16px;
      }
    }
  }
  main {
    .home {
      .pessoal {
        height: calc(100vh - 70px);
        h1 {
          font-size: 3.9rem;
          text-shadow: 0 0 3px rgba(0, 0, 0, 0.114) !important;
        }
        h2 {
          font-size: 1.2rem;
          span {
            &::after {
              height: 6px;
              width: 6px;
            }
          }
        }
        div {
          //z-index: 2;
          a {
            &::after {
              height: 25px;
              width: 25px;
              font-size: 18px;
            }
          }
        }
        .hi {
          height: 200px;
          width: 200px;
        }

        .frames {
          display: none;
        }
        .button {
          a {
            font-size: .94rem;
            &::after {
              height: 25px;
              width: 25px;
              border-radius: 8px;
            }
          }
        }
      }
      .git {
        display: none;
      }
      .social-media {
        ul {
          li {
            a {
              svg {
                width: 20px;
              }
            }
          }
        }
      }
    }
    .separador {
      h1 {
        font-size: 1.3rem;
        small {
          font-size:1rem;
        }
      }

      &::after {
        content: "";
        height: 0.5px;
        width: 100px;
        background-color: #2287f8;
        margin-left: 20px;
      }
      &.sb,
      &.sk {
        &::before {
          content: "Sobre Mim";
          text-transform: uppercase;
          font-size: 70px;
          color: #2287f8;
          font-family: "Rubik";
          position: absolute;
          top: -40px;
          opacity: 0.4;
          left: 50%;
          transform: translateX(-50%);
          z-index: -1;
        }
      }
      &.sk {
        &::before {
          content: "Habilidades";
        }
      }
    }
    .projectos {
      display: grid;
      align-items: center;
      justify-content: space-between;
      margin-top: 60px;
      .image {
        width: 100%;
        height: 350px;
        border-radius: 1rem;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
          border-radius: 1rem;
        }
      }
      .texto {
        position: relative;
        .descricao {
          padding: 15px;
          position: absolute;
          width: 580px;
          right: -151px;
          top: 50%;
          transform: translateY(-50%);
          background-color: #15243df7;
          border-radius: 1rem;
          box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
            0 4px 6px -2px rgba(0, 0, 0, 0.05);
  
          border: 1px solid rgba(255, 255, 255, 0.16);
          border-top: 0px;
          h1 {
            font-size: 24px;
            font-weight: 500;
            font-weight: bold;
            color: #ccd6f6;
          }
          .tools {
            margin: 5px 0 8px;
            span {
              display: inline-flex;
              vertical-align: top;
              -webkit-box-align: center;
              align-items: center;
              max-width: 100%;
              font-weight: 500;
              line-height: 1.2;
              outline: transparent solid 2px;
              outline-offset: 2px;
              min-height: 1.45rem;
              min-width: 1.25rem;
              font-size: 0.75rem;
              padding: 0 5px;
              /* padding-inline-start: 0.5rem;
              padding-inline-end: 0.5rem; */
              border-radius: 0.375rem;
              margin-left: 6px;
              &:nth-child(1) {
                margin-left: 0px;
              }
              span {
                //line-height: 1.2;
                overflow: hidden;
                margin-left: 0px;
                //text-overflow: ellipsis;
                white-space: nowrap;
                font-weight: 600;
              }
              i {
                //color: #e5e5e5;
                font-size: 12px;
              }
            }
          }
          p {
            font-family: "Open Sans";
            font-size: 16px;
            color: #f8f8fa !important;
            a {
              font-size: 15px;
              text-decoration-color: #2287f8;
              font-size: 15px;
              line-height: 0;
            }
          }
          ul {
            margin-top: 15px;
            display: flex;
            align-items: center;
            gap: 10px;
            li {
              a {
                svg {
                  width: 20px;
                  stroke: #ccd6f6;
                  &:hover {
                    stroke: #2287f8;
                  }
                }
              }
            }
          }
        }
      }
  
      &.grid-f-2 {
        .texto {
          .descricao {
            left: -151px !important;
          }
        }
      }
      &:first-of-type {
        margin-top: 0px !important;
      }
    }
  }
}