*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.container {
  width: 1200px;
  margin: 0 auto;
  padding: 0;
  position: relative;
}
.container:after,
.container:before {
  content: " ";
  display: table;
}
.container:after {
  clear: both;
}
.grid-1,
.grid-2,
.grid-3,
.grid-4,
.grid-5,
.grid-6,
.grid-7,
.grid-8,
.grid-9,
.grid-10,
.grid-11,
.grid-12,
.grid-13,
.grid-14,
.grid-15,
.grid-16,
.grid-1-3 {
  float: left;
}


.grid{
	display: grid;
	gap: 20px;
  //align-items: center;
  width: 100%;
}
.grid-2-f{
    grid-template-columns: 1.5fr 2fr;        
  }

  .grid-f-2{
    grid-template-columns: 2fr 1.5fr;        
  }
.grid-2{
  grid-template-columns: 1fr 1fr;        
}
.grid-3{
	    grid-template-columns: 1fr 1fr 1fr;        
}
.grid-4{
        grid-template-columns: 1fr 1fr 1fr 1fr;        
}
.grid-6-10{
  grid-template-columns: 1fr 1.5fr; 
  .grid-6-{
    grid-column: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .grid-10-{
    grid-column: 2;
  }
}
.grid-8-8{
  grid-template-columns: 1.2fr 1fr; 
  .grid-81-{
    grid-column: 1;
  }
  .grid-8-{
    grid-column: 2;
  }
}
.grid-4-10{
  grid-template-columns: 1.5fr 1fr; 
  .grid-4-{
    grid-column: 1;
  }
  .grid-10-{
    grid-column: 2;
  }
}